// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/common/password.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/common/password.tsx");
  import.meta.hot.lastModified = "1723666999155.003";
}
// REMIX HMR END

import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const PasswordInput = ({
  error,
  value,
  name,
  placeHolder,
  autofocus
}) => {
  _s();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const passwordRef = useRef(null);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  return <div className="fv-row mb-8 position-relative">
      <input ref={passwordRef} type={isPasswordVisible ? "text" : "password"} placeholder={placeHolder || "Password"} name={name || "password"} autoComplete="new-password" aria-invalid={error ? true : undefined} aria-describedby="password-error" className={`form-control bg-transparent ${error ? "is-invalid" : ""}`} defaultValue={value && value} autoFocus={autofocus} />
      <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={togglePasswordVisibility} style={{
      cursor: "pointer"
    }}>
        <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
      </span>
      {error && <div className="invalid-feedback" id="password-error">
          {error}
        </div>}
    </div>;
};
_s(PasswordInput, "R2xN/GRwKIWrYXOxogbRsFmQu0w=");
_c = PasswordInput;
export default PasswordInput;
var _c;
$RefreshReg$(_c, "PasswordInput");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;